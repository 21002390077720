import { Controller } from '@hotwired/stimulus'
import { scrollTo } from '../helpers/WindowHelper'

export default class extends Controller {
    connect() {
        this.element.addEventListener('change', this.scrollToSelectedItem.bind(this))
    }

    scrollToSelectedItem(event) {
        const selectedItem = this.element.value

        const pageSectionSelected = document.getElementById(selectedItem)

        if (pageSectionSelected) {
            scrollTo(pageSectionSelected)
        }
    }

    disconnect() {
        this.element.removeEventListener('change')
    }
}
