import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['form']
  static values = {
    disableSubmitOnEnter: Boolean,
  }

  initialize() {
    this.disableSubmitOnEnter = this.disableSubmitOnEnter.bind(this)
  }

  connect() {
    if (this.isDisableSubmitOnEnter)
      this.formElement.addEventListener('keypress', this.disableSubmitOnEnter)
  }

  disconnect() {
    if (this.isDisableSubmitOnEnter)
      this.formElement.removeEventListener('keypress', this.disableSubmitOnEnter)
  }

  get formElement() {
    return this.hasFormTarget ? this.formTarget : this.element
  }

  submit() {
    this.formElement.requestSubmit()
  }

  disableSubmitOnEnter(event) {
    if (['Enter'].includes(event.key)) {
      event.preventDefault()
    }
  }

  isDisableSubmitOnEnter() {
    return this.disableSubmitOnEnterValue === true
  }
}
