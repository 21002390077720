import { Controller } from '@hotwired/stimulus'
import TurboClient from '../api/TurboClient'

export default class extends Controller {
  static targets = ['form', 'button', 'stripeCardIdInput']

  initialize() {
    this.formTarget.addEventListener('submit', event => {
      event.preventDefault()
      this.onSubmit()
    })

    this.formTarget.addEventListener('keypress', event => {
      if (event.key === 'Enter') {
        event.preventDefault()
        this.onSubmit()
      }
    })
  }

  async onSubmit() {
    // Custom button without form
    const buttonElement = this.buttonTarget
    const originalButtonElementText = buttonElement.textContent
    buttonElement.textContent = buttonElement.dataset.disableWith
    buttonElement.disabled = true

    if (this.hasStripeCardIdInputTarget) {
      const selectedCardId = this.getCheckedRadioValue('credit-card')
      this.stripeCardIdInputTarget.value = selectedCardId || ''
    }

    const turboClient = new TurboClient(this.formTarget)
    const turboClientResponse = await turboClient.perform()

    if (!turboClientResponse.response?.redirected) {
      buttonElement.textContent = originalButtonElementText
      buttonElement.disabled = false
    }
  }

  getCheckedRadioValue(inputName) {
    const radios = Array.from(document.getElementsByName(inputName))
    const checkedValue = radios.find(radio => radio.checked)?.value

    return checkedValue
  }
}
