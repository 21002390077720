import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['toggleElement']
  static classes = ['name']
  static values = {
    selector: String,
    connect: Boolean,
  }

  connect() {
    if (this.hasConnectValue) {
      this.toggle(null)
    }
  }

  toggle(event) {
    const element = this.targetElement || event?.currentTarget
    const nameClass = event?.currentTarget.dataset.addCssClassNameClass || this.nameClass

    if (element) {
      element.classList.add(nameClass)
    }
  }

  get targetElement() {
    if (this.hasToggleElementTarget) {
      return this.toggleElementTarget
    } else if (this.hasSelectorValue) {
      return document.querySelector(this.selectorValue)
    } else {
      return undefined
    }
  }
}
