import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { keyName: String }

  connect() {
    const urlSeartParams = new URLSearchParams(window.location.search)
    urlSeartParams.delete(this.keyNameValue)

    const urlWithQueryStringKeyRemoved = `${window.location.origin}${window.location.pathname}?${urlSeartParams.toString()}`
    window.history.pushState({ path: urlWithQueryStringKeyRemoved }, '', urlWithQueryStringKeyRemoved)
  }
}
