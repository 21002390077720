import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { containerId: String }

  empty() {
    const containerElement = document.getElementById(this.containerIdValue)
    containerElement.innerHTML = ''
  }
}
