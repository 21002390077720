import { Controller } from '@hotwired/stimulus'

/**
 * Sets the state input field according to selected country
 */
export default class extends Controller {
  static values = { statesByCountry: Object }
  static targets = ['stateParent', 'countryInput']

  setState(e) {
    const selectedCountryCode = e.target.value
    this.setStatesFromCountry(selectedCountryCode)
  }

  setStatesFromCountry(countryCode) {
    const states = this.statesByCountryValue[countryCode]

    if (states) this.setStateOptions(states)
    else this.resetAndHideStateField()
  }

  setStateOptions(states) {
    const select = this.stateSelect
    select.innerHTML = ''
    this.appendStateOptions(select, states)
    this.stateParentTarget.classList.remove('hidden')
  }

  resetAndHideStateField() {
    this.stateSelect.innerHTML = ''
    this.stateParentTarget.classList.add('hidden')
  }

  appendStateOptions(element, states) {
    element.appendChild(this.generateOption())

    for (const stateCode in states) {
      if (!Object.hasOwn(states, stateCode)) continue

      const option = this.generateOption({ label: states[stateCode], value: stateCode })

      element.appendChild(option)
    }
  }

  generateOption({ label = null, value = null } = {}) {
    const option = document.createElement('option')
    if (value) option.value = value
    if (label) option.innerText = label
    return option
  }

  get stateSelect() {
    return this.stateParentTarget.querySelector('select')
  }
}
