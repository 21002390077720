// categories_nav_controller can be reused here. Will require a refactor to remove this file and refactor part searches partial
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'partCategoryId',
    'partSubcategoryId',
    'form',
    'tabCategory',
    'tabSubcategory'
  ]

  select(event) {
    const partCategoryId = event.currentTarget.dataset.partCategoryId
    const partSubcategoryId = event.currentTarget.dataset.partSubcategoryId
    const formActionUrl = event.currentTarget.dataset.formActionUrl

    this.partCategoryIdTarget.value = partCategoryId ? partCategoryId : ''
    this.partSubcategoryIdTarget.value = partSubcategoryId
      ? partSubcategoryId
      : ''
    this.formTarget.action = formActionUrl

    this.tabCategoryTargets.forEach(tab =>
      tab.dataset.partCategoryId === partCategoryId
        ? tab.classList.add('active')
        : tab.classList.remove('active')
    )

    this.tabSubcategoryTargets.forEach(tab =>
      tab.dataset.partCategoryId === partCategoryId &&
      tab.dataset.partSubcategoryId === partSubcategoryId
        ? tab.classList.add('active')
        : tab.classList.remove('active')
    )
  }
}
