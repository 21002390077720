import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["hiddenInput"]
  static values = {
    codeLength: String
  }

  handlePaste(e) {
    e.preventDefault();

    const paste = (e.clipboardData || window.clipboardData).getData("text");
    const pasteArray = paste.split("").filter(this.stringIsDigit);

    for (let i = 0; i < pasteArray.length; i++) {
      const current = this.element.querySelector(`input#digit-${i + 1}`);

      if (current !== null) {
        current.value = pasteArray[i];
      }
    }

    this.updateHiddenInput();
  }

  handleInput(e) {
    const digitValue = e.data;

    if (digitValue == null) { return; }

    if (this.stringIsDigit(digitValue)) {
      const next = this.element.querySelector(`input#${e.currentTarget.dataset.next}`);

      if(next !== null) {
        next.focus();
      }

      this.updateHiddenInput();
    } else {
      e.currentTarget.value = "";
    }
  }

  handleKeyUp(e) {
    if (e.key === "Backspace") {
      const prev = this.element.querySelector(`input#${e.currentTarget.dataset.previous}`);

      if (prev !== null) {
        prev.focus();
      }
    }
  }

  handleFocus(e) {
    e.target.select();
  }

  updateHiddenInput() {
    let otpCode = "";

    for (var i = 1; i < (Number(this.codeLengthValue) + 1); i += 1) {
      otpCode += this.element.querySelector(`input#digit-${i}`).value;
    }

    this.hiddenInputTarget.value = otpCode;
  }

  stringIsDigit(str) {
    return /^\d+$/.test(str);
  }
}
