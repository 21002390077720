import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    targetId: String,
  }

  initialize() {
    this.handleClick = this.handleClick.bind(this)
  }

  connect() {
    this.element.addEventListener('change', this.handleClick)
  }

  disconnect() {
    this.element.removeEventListener('change', this.handleClick)
  }

  handleClick() {
    const targetElement = document.getElementById(this.targetIdValue)
    targetElement.checked = true
  }
}
