import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['categoryInput', 'subcategoryInput']

  toggle(event) {
    if (!event.currentTarget.checked) {
      this.categoryInputTarget.value = ''
      this.subcategoryInputTarget.value = ''
    }

    this.element.requestSubmit()
  }
}
