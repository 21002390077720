import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['checkbox', 'categoryInput', 'subcategoryInput', 'form']

  toggle(event) {
    this.checkboxTargets.forEach(checkboxTarget => {
      if (checkboxTarget !== event.currentTarget) {
        checkboxTarget.checked = false
      }
    })
  }
}
