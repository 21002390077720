import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "button"]

  toggleFormVisibility() {
    this.formTarget.classList.toggle("hidden");
    this.buttonTarget.classList.toggle("hidden");
  }
}
