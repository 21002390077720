import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['form', 'hiddenActions']
  static values = {
    checkboxesSelector: String,
  }

  connect() {
    if (!this.hasCheckboxesSelectorValue) console.error('Missing required checkboxesSelectorValue')
  }

  toggleActions() {
    const hasAtLeastOneCheckboxChecked = this.checkboxesArray.some(checkbox => checkbox.checked)
    this.hiddenActionsTargets.forEach(element =>
      element.classList.toggle('hidden', !hasAtLeastOneCheckboxChecked)
    )
  }

  /*
   * @return NodeList
   */
  get checkboxes() {
    return document.querySelectorAll(this.checkboxesSelectorValue)
  }

  /*
   * @return Node[]
   */
  get checkboxesArray() {
    return Array.from(this.checkboxes)
  }
}
