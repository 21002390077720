import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input']

  increment() {
    this.add(1)
  }

  decrement() {
    this.add(-1)
  }

  add(value) {
    this.inputTarget.value = Math.max(Number(this.inputTarget.value) + value, 1)
  }

  setMinimum() {
    if (!this.inputTarget.value || this.inputTarget.value < 1) {
      this.inputTarget.value = 1
    }
  }

  input() {
    if (!this.inputTarget.value) {
      return
    }
    this.setMinimum()
  }
}
