import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    displayName: String,
    email: String,
  }

  initialize() {
    this.element.onload = this.applyWidgetSettings()
    this.reloadLiveChat = this.reloadLiveChat.bind(this)
  }

  connect() {
    window.addEventListener('turbo:before-render', this.reloadLiveChat)
  }

  disconnect() {
    window.removeEventListener('turbo:before-render', this.reloadLiveChat)
  }

  applyWidgetSettings() {
    // Used to remove the "Choose Department" field from the chat form
    window.zESettings = {
      webWidget: {
        chat: { departments: { enabled: [] } },
      },
    }

    const name = this.displayNameValue
    const email = this.emailValue

    // https://developer.zendesk.com/api-reference/widget/api/#zeidentify
    if (name && email && undefined !== zE) {
      zE(function () {
        zE.identify({
          name: name,
          email: email,
        })
      })
    }
  }

  reloadLiveChat() {
    // To reload the live chat widget with turbo drive
    // Documented here: https://mentalized.net/journal/2019/04/26/zendesk-web-widget-turbolinks/
    window.zEACLoaded = undefined
  }
}
