import { Controller } from '@hotwired/stimulus'

/**
 * Meant to be used alongside modal_controller.js
 */
export default class extends Controller {
  static values = {
    selector: String,
  }

  connect() {
    this.element.addEventListener('turbo:submit-end', event => {
      if (event.detail.success) {
        // this.modalElement.modal comes from modal_controller.js
        this.modalElement.modal.close()
      }
    })
  }

  get modalElement() {
    return document.querySelector(this.selectorValue)
  }
}
