import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['emailInput', 'addButton']

  initialize() {
    this.addEmail = this.addEmail.bind(this)
  }

  connect() {
    this.emailInputTarget.addEventListener('keypress', this.addEmail)
  }

  disconnect() {
    this.emailInputTarget.removeEventListener('keypress', this.addEmail)
  }

  addEmail(event) {
    if (['Enter', ','].includes(event.key)) {
      event.preventDefault()
      this.add()
    }
  }

  add() {
    this.addButtonTarget.click()
    this.clearInput()
  }

  remove(event) {
    const removeSelector = event.currentTarget.dataset.removeClosestSelector
    event.target.closest(removeSelector).remove()
  }

  clearInput() {
    this.emailInputTarget.value = ''
  }
}
