import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['replace']
  static values = {
    cardCount: { type: Number, default: 10 },
    cardClass: { type: String, default: 'leaderboard-card-gradient-loader-card' },
    wrappperClass: { type: String, default: 'leaderboard-card-gradient-loader' },
  }

  initialize() {
    this.gradientLoaderHTML = `<div class='${this.cardClassValue}'><div class='loading-dots'></div></div>`
  }

  insert() {
    if (this.hasReplaceTarget) {
      this.replaceTarget.outerHTML = `<div class='${
        this.wrappperClassValue
      }'>${this.gradientLoaderHTML.repeat(Number(this.cardCountValue))}</div>`
    }
  }
}
