import { Controller } from '@hotwired/stimulus'
import currencyJS from 'currency.js'

export default class extends Controller {
  static targets = ['sum', 'input']

  updateSum() {
    this.sumTarget.value = this.inputTargets.reduce(
      (sum, input) => currencyJS(sum).add(Number(input.value)),
      0
    )
  }
}
