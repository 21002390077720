import { Controller } from '@hotwired/stimulus'

const unsavedChangesMessage = "<div class='incomplete-message'><i>Unsaved Changes</i></div>"

export default class extends Controller {
  displayMessage() {
    const messageContainer = document.getElementById('dashboard-header-right-form-message')
    messageContainer.innerHTML = unsavedChangesMessage
  }
}
