import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['categoryId', 'subcategoryId']

  select(event) {
    const categoryId = event.currentTarget.dataset.filterValue.split('-').shift(0)
    const subcategoryId = event.currentTarget.dataset.filterValue.split('-').pop()

    this.categoryIdTarget.value = event.currentTarget.checked ? categoryId : ''
    this.subcategoryIdTarget.value = event.currentTarget.checked ? subcategoryId : ''

    this.element.requestSubmit()
  }
}
