// To disable form submit button till selection is made

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    submitButtonId: String,
  }

  enableSubmitButton() {
    const submitButton = document.getElementById(this.submitButtonIdValue)
    submitButton.disabled = false
  }
}
