import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['trigger', 'flyout']
  static classes = ['triggerOpen']
  static values = {
    triggerOpenSelector: String,
  }

  initialize() {
    this.clickListener = this.clickListener.bind(this)
  }

  connect() {
    document.body.addEventListener('click', this.clickListener)
  }

  disconnect() {
    document.body.removeEventListener('click', this.clickListener)
  }

  toggle(event) {
    this.flyoutTarget.classList.toggle('dashboard-flyout-open')
    this.toggleTriggerOpen(event)
  }

  toggleTriggerOpen(event = undefined) {
    if (this.hasTriggerOpenClass) {
      this.triggerTarget.classList.toggle(this.triggerOpenClass)
      return
    }

    if (event) {
      const flyoutTriggerOpenClass = event.target.dataset.flyoutTriggerOpenClass

      if (flyoutTriggerOpenClass) {
        this.triggerTarget.classList.toggle(flyoutTriggerOpenClass)
        return
      }
    }

    if (this.hasTriggerOpenSelectorValue) {
      const triggerElement = document.querySelector(this.triggerOpenSelectorValue)
      const className = triggerElement.dataset.flyoutTriggerOpenClass
      triggerElement.classList.toggle(className)
    }
  }

  close() {
    this.flyoutTarget.classList.remove('dashboard-flyout-open')
    this.toggleTriggerOpen()
  }

  clickListener(event) {
    const flyoutTarget = this.flyoutTarget
    const triggerTargets = this.triggerTargets

    const reactModalOverlayElement = document.querySelector('.react-modal-overlay')
    const genericModals = document.querySelectorAll('.generic-modal')
    let genericModalBeingClicked

    genericModals.forEach(modal => {
      if (modal.contains(event.target) || event.target.isEqualNode(modal)) {
        genericModalBeingClicked = true
      }
    })

    const isOpen = flyoutTarget.classList.contains('dashboard-flyout-open')
    const clickedOutside = !flyoutTarget.contains(event.target)

    const targetBeingClicked = triggerTargets.some(
      target => target.contains(event.target) || event.target.isEqualNode(target)
    )

    if (
      isOpen &&
      clickedOutside &&
      !targetBeingClicked &&
      !reactModalOverlayElement &&
      !genericModalBeingClicked
    ) {
      this.close()
    }
  }
}
