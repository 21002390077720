import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['replace']
  static values = {
    cardCount: Number,
  }

  initialize() {
    this.gradientLoaderHTML =
      "<div class='design-card-gradient-loader-card'><div class='loading-dots'></div></div>"
  }

  insert() {
    if (this.hasReplaceTarget) {
      this.replaceTarget.outerHTML = `<div class='design-card-gradient-loader'>${this.gradientLoaderHTML.repeat(
        Number(this.cardCountValue)
      )}</div>`
    }
  }
}
