export const ButtonMarkdownTemplate = "{::button url='https://vention.io' text='Call to action' /}"

export const AccordionMarkdownTemplate =
  "{::accordion title='configAxisDirection' code='(axis, direction)'}{:/accordion}"

export const YoutubeVideoMarkdownTemplate = "{::youtube_video id='CkjdrushSjc' /}"

export const VimeoVideoMarkdownTemplate = "{::vimeo_video id='466687324' /}"

export const NoTocMarkdownTemplate = '{: .no_toc}'
export const NoTocMarkdownTemplateFormatted = '{: .no\\_toc}'

export const ImageWidthMarkdownTemplate = '{:width="300"}'
