import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['toggleable', 'button']

  toggle(event) {
    event.preventDefault()
    const { toggleableTarget, buttonTarget, data } = this

    toggleableTarget.classList.toggle('hidden')

    const isHidden = toggleableTarget.classList.contains('hidden')

    buttonTarget.textContent = isHidden ? data.get('more') : data.get('less')
  }
}
