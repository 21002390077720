import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { cookieName: String, cookieMaxAge: Number }

  dismiss() {
    this.setCookie()
    this.element.classList.add('hidden')
  }

  setCookie() {
    if (!this.hasCookieNameValue) return
    document.cookie = `${this.cookieNameValue}=1; max-age=${this.cookieMaxAge}; secure`
  }

  get cookieMaxAge() {
    const defaultMaxAgeInSeconds = 24 * 60 * 60
    return this.hasCookieMaxAgeValue ? this.cookieMaxAgeValue : defaultMaxAgeInSeconds
  }
}
