import { Controller } from '@hotwired/stimulus'
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  static targets = ['entries', 'pagination']

  initialize() {
    let options = {
      rootMargin: '200px',
    }

    this.intersectionObserver = new IntersectionObserver(
      entries => this.processIntersectionEntries(entries),
      options
    )
  }

  connect() {
    if (this.hasPaginationTarget) {
      this.intersectionObserver.observe(this.paginationTarget)
    }
  }

  disconnect() {
    if (this.hasPaginationTarget) {
      this.intersectionObserver.unobserve(this.paginationTarget)
    }
  }

  processIntersectionEntries(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.loadMore()
      }
    })
  }

  async loadMore() {
    const nextPage = this.paginationTarget.querySelector("a[rel='next']")
    if (nextPage == null) {
      return
    }

    const url = nextPage.href

    const request = new FetchRequest('get', url, {
      responseKind: 'turbo-stream',
    })

    await request.perform()
  }
}
