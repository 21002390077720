import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["countdownNumber", "coutdownText", "resendButton"]
  static outlets = ["otp-resend-form"]

  connect() {
    this.startCountDown();
  }

  startCountDown() {
    this.timer = setInterval(() => {
      const duration = parseInt(this.countdownNumberTarget.textContent) - 1;

      if (duration <= 0) {
        clearInterval(this.timer);

        this.resendButtonTarget.style.display = "block";
        this.coutdownTextTarget.style.display = "none";
      }

      this.countdownNumberTarget.textContent = duration;
    }, 1000);
  }

  disconnect() {
    clearInterval(this.timer);
  }

  submitForm() {
    this.otpResendFormOutletElement.requestSubmit();
  }
}