import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    identifier: String,
    className: String,
  }

  remove() {
    const hiddenInputs = document.querySelectorAll(`.${this.classNameValue}`)
    hiddenInputs.forEach(element => {
      if (element.value === this.identifierValue) {
        element.remove()
      }
    })
  }
}
