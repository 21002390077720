import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['button']

  connect() {
    document.addEventListener('keyup', this.escClose)
  }

  disconnect() {
    document.removeEventListener('keyup', this.escClose)
  }

  close() {
    this.buttonTarget.requestSubmit()
  }

  escClose = event => {
    if (event.key === 'Escape') this.close()
  }
}
