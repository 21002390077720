import { Controller } from '@hotwired/stimulus'
import { scrollTo } from '../helpers/WindowHelper'

export default class extends Controller {
  static targets = ['anchorLink']

  initialize() {
    this.scrollEndHandler = this.scrollEndHandler.bind(this)
    this.getTimeoutHandle = this.getTimeoutHandle.bind(this)
    this.onScrollHandler = this.onScrollHandler.bind(this)
  }

  // placeholder declared for binding
  scrollEndHandler() {}

  setScrollEndHandler(scrollEndHandler) {
    this.scrollEndHandler = scrollEndHandler
  }

  setTimeoutHandle(timeout) {
    this.timeoutHandle = timeout
  }

  getTimeoutHandle() {
    return this.timeoutHandle
  }

  disconnect() {
    window.removeEventListener('scroll', this.scrollEndHandler)
    window.removeEventListener('scroll', this.resetClasses)
    window.clearTimeout(this.getTimeoutHandle())
  }

  resetClasses() {
    this.anchorLinkTargets.forEach(anchorLink => {
      anchorLink.classList.remove('anchor-link-force')
      anchorLink.classList.remove('anchor-link-force-active')
    })
  }

  onScrollHandler() {
    this.resetClasses()

    window.removeEventListener('scroll', this.onScrollHandler)
  }

  smoothScroll(element) {
    scrollTo(element)

    return new Promise((resolve, _reject) => {
      this.setScrollEndHandler(() => {
        // Clear timeout throughout the scroll
        window.clearTimeout(this.getTimeoutHandle())

        // Set a timeout to run after scrolling ends
        this.setTimeoutHandle(
          window.setTimeout(() => {
            // Scrolling has stopped
            window.removeEventListener('scroll', this.scrollEndHandler)
            resolve()
          }, 66)
        )
      })

      window.addEventListener('scroll', this.scrollEndHandler)
    })
  }

  scroll(event) {
    event.preventDefault()
    const elementId = event.currentTarget.dataset.anchorId
    const element = document.getElementById(elementId)
    this.resetClasses()
    window.removeEventListener('scroll', this.onScrollHandler)

    if (element) {
      this.anchorLinkTargets.forEach(anchorLink => {
        anchorLink.classList.add('anchor-link-force')
      })

      event.currentTarget.classList.add('anchor-link-force-active')

      this.smoothScroll(element)
        .then(() => {
          window.addEventListener('scroll', this.onScrollHandler)
        })
        .catch(() => {
          window.removeEventListener('scroll', this.scrollEndHandler)
        })
    }
  }
}
