import { Controller } from '@hotwired/stimulus'
import Tribute from 'tributejs'

export default class extends Controller {
  static targets = ['field']
  static values = {
    suggestions: Array,
  }

  connect() {
    this.initializeTribute()
  }

  initializeTribute() {
    this.tribute = new Tribute({
      trigger: '@',
      allowSpaces: true,
      requireLeadingSpace: true,
      lookup: function (person) {
        return `${person.name} ${person.value}`
      },
      menuShowMinLength: 0,
      menuItemLimit: 20,
      menuItemLength: 10,
      menuItemTemplate: function (item) {
        return `${item.original.name} (${item.original.value})`
      },
      values: this.suggestionsValue,
      selectTemplate: function (item) {
        return `${item.original.value}, `
      },
    })
    this.tribute.attach(this.fieldTarget)
  }

  disconnect() {
    this.tribute.detach(this.fieldTarget)
  }
}
