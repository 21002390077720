import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input']
  static values = {
    selector: String,
  }

  clear() {
    this.targetElement.value = ''
  }

  update(event) {
    this.targetElement.value = event.currentTarget.value
  }

  focus() {
    this.targetElement.focus()
  }

  get targetElement() {
    if (this.hasInputTarget) {
      return this.inputTarget
    } else if (this.hasSelectorValue) {
      return document.querySelector(this.selectorValue)
    } else {
      console.error('Missing input target or selector')
      return undefined
    }
  }
}
