import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { url: String }

  initialize() {
    // Using window.open can return null due to certain ad blockers
    // Instead a link element is generated and removed
    const linkElement = document.createElement('a')

    Object.assign(linkElement, {
      class: 'hidden',
      href: this.urlValue,
      target: '_blank',
      rel: 'noopener',
    })

    linkElement.click()
    linkElement.remove()
  }
}
