import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['toggleable', 'checkbox']

  toggle(event) {
    event.preventDefault()
    event.currentTarget.classList.toggle('active')
    this.toggleableTarget.classList.toggle('hidden')
  }
}
