import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static values = { for: String }

    initialize() {
        this.updateLabel = this.updateLabel.bind(this)
    }

    connect() {
        this.selectElement = this.element.querySelector(`#${this.forValue}`)

        this.labelElement = this.element.querySelector('label')
        this.originalLabelValue = this.labelElement.innerHTML

        this.selectElement.addEventListener("change", this.updateLabel)
        this.updateLabel()
    }

    disconnect() {
        this.selectElement.removeEventListener("change", this.updateLabel)
    }

    updateLabel() {
        const selectedItems = this.selectElement.querySelectorAll('option:checked')
        const nbSelected = selectedItems.length

        this.labelElement.innerHTML = `${this.originalLabelValue} (${nbSelected})`
    }
}