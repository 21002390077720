import { Controller } from '@hotwired/stimulus'
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  changeShippingOptionIfChecked(event) {
    const inputElement = event.target

    if (inputElement.checked) {
      const request = new FetchRequest(
        'patch',
        '/checkout/shipping/order_details_sidebar',
        {
          responseKind: 'turbo-stream',
          body: JSON.stringify({ shipping_option: { vention_shipping_option_code: inputElement.value }})
        }
      )

      request.perform()
    }
  }

  clearShippingOption() {
    const request = new FetchRequest(
      'patch',
      '/checkout/shipping/order_details_sidebar',
      {
        responseKind: 'turbo-stream',
        body: JSON.stringify({ shipping_option: { vention_shipping_option_code: '' }})
      }
    )

    request.perform()
  }
}
