import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static values = {
        checkboxId: String,
        toggleId: String,
        defaultToggleDisplay: String
    };

    connect() {
        const displayValue = this.defaultToggleDisplayValue || 'block'
        this.disableFields(displayValue === 'none')
        this.toggleElement.style.display = displayValue
        this.checkboxElement.addEventListener('change', this.toggleDisplay.bind(this))
    }

    toggleDisplay() {
        const displayValue = this.toggleElement.style.display === 'none' ? 'block' : 'none'

        this.disableFields(displayValue === 'none')

        this.toggleElement.style.display = displayValue
    }

    disableFields(disable) {
        const inputElements = this.toggleElement.querySelectorAll('input, select, checkbox, textarea')

        inputElements.forEach((element) => {
            element.disabled = disable
        })
    }

    get checkboxElement() {
        return document.getElementById(this.checkboxIdValue)
    }

    get toggleElement() {
        return document.getElementById(this.toggleIdValue)
    }
}
