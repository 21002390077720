import { Controller } from '@hotwired/stimulus'
import Cleave from 'cleave.js'

export default class extends Controller {
  initialize() {
    new Cleave('.date-mask', {
      date: true,
      datePattern: ['m', 'y'],
    })
  }
}
