import { Controller } from '@hotwired/stimulus'
import { downloadPartStepFile } from '../helpers/DownloadHelper'

export default class extends Controller {
  static values = { partId: String }

  download() {
    downloadPartStepFile(this.partIdValue)
  }
}
