import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static outlets = ['dashboard-flyout']
  toggle(e) {
    if (e.target.dataset.preventFlyout) {
      return
    }

    this.dashboardFlyoutOutlet.toggle()
    this.dashboardFlyoutOutlet.disconnect()
    setTimeout(() => {
      this.dashboardFlyoutOutlet.connect()
    }, 0)
  }

  close() {
    this.toggle()
  }
}
