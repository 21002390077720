import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['toggleContainer', 'toggleElement', 'innerText']
  static values = { replacedInnerText: String, defaultInnerText: String }

  connect() {
    const buttonToDisable = document.getElementById('checkout-shipping-button')
    if (buttonToDisable) {
      buttonToDisable.disabled = true
    }
  }

  disconnect() {
    const buttonToDisable = document.getElementById('checkout-shipping-button')
    if (buttonToDisable) {
      const shippingAddressForm = document.getElementById('new-shipping-address-form')
      buttonToDisable.disabled = shippingAddressForm !== null
    }
  }

  toggle(event) {
    const originalText = event.currentTarget.dataset.originalText
    const hiddenText = event.currentTarget.dataset.hiddenText

    if (this.hasToggleElementTarget) {
      this.toggleElementTarget.classList.toggle('active')

      const replaceInnerText =
        this.hasInnerTextTarget &&
        this.hasReplacedInnerTextValue &&
        this.toggleElementTarget.classList.contains('active')

      const resetInnerText =
        this.hasInnerTextTarget &&
        this.hasDefaultInnerTextValue &&
        !this.toggleElementTarget.classList.contains('active')

      if (replaceInnerText) {
        this.innerTextTarget.innerText = this.replacedInnerTextValue
      }

      if (resetInnerText) {
        this.innerTextTarget.innerText = this.defaultInnerTextValue
      }
    }

    if (this.toggleContainerTarget.classList.contains('hidden')) {
      this.toggleContainerTarget.classList.remove('hidden')
      if (hiddenText) {
        event.currentTarget.textContent = hiddenText
      }
    } else {
      this.toggleContainerTarget.classList.add('hidden')
      if (hiddenText) {
        event.currentTarget.textContent = originalText
      }
    }
  }
}
