// Toggleable nested form for repeatable attributes
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['addButton', 'template']

  add_association() {
    var content = this.templateTarget.innerHTML.replace(
      /NEW_RECORD/g,
      new Date().getTime()
    )
    this.addButtonTarget.insertAdjacentHTML('beforebegin', content)
  }

  remove_association(event) {
    let wrapper = event.target.closest('.nested-fields')

    const { newRecord, confirmDestroy } = wrapper.dataset

    if (newRecord == 'true') {
      wrapper.remove()
    } else {
      // Destroying persisted record
      let destroyConfirmed = true
      if (confirmDestroy == 'true') {
        // Nested form needs confirmation before destroying
        destroyConfirmed = window.confirm('Are you sure you want to remove this saved tax exemption?')
      }

      if (!destroyConfirmed) return

      wrapper.querySelector("input[name*='_destroy']").value = 1
      wrapper.style.display = 'none'
    }
  }
}
