// this controller unmounts the machine cloud app react app in the dashboard
// as the machine cloud app contains a callback on unmount to remove an interval timer
// which attempts to reconnect websockets every 5 seconds.
// previously the component was not unmounted when turbo navigating

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  initialize() {
    this.unmountReact = this.unmountReact.bind(this)
  }
  connect() {
    ReactRailsUJS.handleEvent('turbo:before-render', this.unmountReact)
  }
  disconnect() {
    ReactRailsUJS.removeEvent('turbo:before-render', this.unmountReact)
  }

  unmountReact() {
    ReactRailsUJS.handleUnmount()
  }
}
