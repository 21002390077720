import { Controller } from '@hotwired/stimulus'
import Editor from '@toast-ui/editor'

/**
 * Use on a hidden field.
 */
export default class extends Controller {
  static values = {
    toolbarItems: { type: Array, default: [['bold'], ['ul', 'ol', 'indent', 'outdent'], ['link']] },
    hideModeSwitch: { type: Boolean, default: false },
    placeHolder: { type: String, default: '' },
  }
  connect() {
    const inputElement = this.element
    const editorElement = document.createElement('div')

    editorElement.setAttribute('id', `${inputElement.id}-editor`)
    inputElement.classList.add('hidden')
    inputElement.after(editorElement)

    const formElement = inputElement.closest('form')

    this.editorElement = editorElement
    this.formElement = formElement

    formElement.addEventListener('keypress', this.onFormKeyPress.bind(this))
    formElement.addEventListener('submit', this.onFormSubmit.bind(this))

    this.editor = new Editor({
      el: editorElement,
      height: '300px',
      minHeight: '300px',
      initialEditType: 'wysiwyg',
      autofocus: false,
      initialValue: inputElement.value,
      usageStatistics: false,
      toolbarItems: this.toolbarItemsValue,
      hideModeSwitch: this.hideModeSwitchValue,
      placeholder: this.placeHolderValue,
    })

    window.tuiEl = this.editor
  }

  disconnect() {
    this.formElement.removeEventListener('keypress', this.onFormKeyPress)
    this.formElement.removeEventListener('submit', this.onFormSubmit)

    if (this.editorElement) {
      this.editorElement.remove()
    }
  }

  onFormSubmit() {
    this.element.value = this.editor.getMarkdown()
  }

  // Need to disable form submit on enter as there are additional
  // fields in the editor that shouldn't submit the form on enter
  onFormKeyPress(event) {
    if (event.keyCode === 13) {
      event.preventDefault()
    }
  }
}
