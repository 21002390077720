import { Controller } from '@hotwired/stimulus'
import { extractDomainFromEmail } from '../helpers/StringHelper'
export default class extends Controller {
  static targets = ['input', 'warning']
  static values = {
    blacklistedDomains: Array,
  }

  initialize() {
    this.boundCheckEmailAgainstBlacklist = this.checkEmailAgainstBlacklist.bind(this)
  }

  connect() {
    this.inputTarget.addEventListener('blur', this.boundCheckEmailAgainstBlacklist)
  }

  disconnect() {
    this.inputTarget.removeEventListener('blur', this.boundCheckEmailAgainstBlacklist)
  }

  checkEmailAgainstBlacklist(event) {
    const domain = extractDomainFromEmail(event.target.value)

    if (this.blacklistedDomainsValue.includes(domain)) {
      this.warningTarget.classList.remove('hidden')
    } else {
      this.warningTarget.classList.add('hidden')
    }
  }
}
