import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['form']

  initialize() {
    this.changeListener = this.changeListener.bind(this)
  }

  connect() {
    const inputElements = this.formTarget.querySelectorAll('input')

    inputElements?.forEach(inputElement => {
      inputElement.addEventListener('keypress', this.changeListener)
    })
  }

  disconnect() {
    const inputElements = this.formTarget.querySelectorAll('input')

    inputElements?.forEach(inputElement => {
      inputElement.removeEventListener('keypress', this.changeListener)
    })
  }

  changeListener(event) {
    if (event.target.parentElement.classList.contains('field_with_errors')) {
      const errorElement = event.target.parentElement.nextElementSibling
      errorElement.remove()
      event.target.parentElement.classList.remove('field_with_errors')
    }
  }
}
