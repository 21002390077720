import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['button', 'toggleable', 'checkbox', 'circleIcon']

  initialize() {
    const activeCheckbox = this.checkboxTargets?.find(checkbox => checkbox.checked)

    if (activeCheckbox) {
      this.toggleableTargets.forEach(toggleableTarget => {
        if (!toggleableTarget.contains(activeCheckbox)) {
          toggleableTarget.classList.toggle('hidden')
        }
      })
    }
  }

  removeHidden(event) {
    event.preventDefault()

    this.toggleableTargets.forEach(toggleableTarget => {
      if (!toggleableTarget.classList.contains('hidden') && toggleableTarget !== event.currentTarget.nextElementSibling) {
        toggleableTarget.classList.toggle('hidden')
      }
      this.toggleActiveIcon(toggleableTarget)
    })
  }

  toggleCheckbox(event) {
    this.toggleableTargets.forEach(toggleableTarget => {
      if (!toggleableTarget.classList.contains('hidden') && !toggleableTarget.contains(event.currentTarget)) {
        toggleableTarget.classList.toggle('hidden')
      }
      this.toggleActiveIcon(toggleableTarget)
    })
  }

  toggleActiveIcon(target) {
    if (target.classList.contains('hidden')) {
      target.previousElementSibling.classList.add('active')
    } else {
      target.previousElementSibling.classList.remove('active')
    }
  }
}
