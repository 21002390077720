import { Controller } from '@hotwired/stimulus'

/**
 * Triggers DOM reflow
 */
export default class extends Controller {
  static values = {
    selector: String,
  }

  resetAnimation() {
    const element = this.targetElement
    element.style.animation = 'none'
    element.offsetHeight /* trigger reflow */
    element.style.animation = null
  }

  get targetElement() {
    if (this.hasSelectorValue) {
      return document.querySelector(this.selectorValue)
    } else {
      return undefined
    }
  }
}
