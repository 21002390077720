import { Controller } from '@hotwired/stimulus'

/**
 * Submit a form on blur (click outside input element).
 * For inputs outside of the form use targets "form" and "formFields"
 * to specify the <form> and the form inputs parents.
 */
export default class extends Controller {
  static targets = ['hiddenSubmitButton', 'form', 'formFields']

  connect() {
    const inputGroups = this.formFieldsParents.map(parent =>
      parent.querySelectorAll('input, checkbox, select')
    )

    inputGroups.forEach(inputGroup => {
      inputGroup.forEach(input => {
        input.addEventListener('blur', () => this.formElement.requestSubmit())
      })
    })
  }

  get formElement() {
    return this.hasFormTarget ? this.formTarget : this.element
  }

  get formFieldsParents() {
    return this.hasFormFieldsTarget ? this.formFieldsTargets : [this.element]
  }
}
