import { Controller } from '@hotwired/stimulus'
import Sortable from 'sortablejs'

export default class extends Controller {
  static targets = ['container', 'hiddenField', 'orderColumn']
  static values = { itemId: Number }

  initialize() {
    new Sortable(this.containerTarget, {
      animation: 350,
      chosenClass: 'sortable-chosen',
      dragClass: 'sortable-drag',
      onEnd: event => {
        const itemInDifferentPlace = event.oldIndex !== event.newIndex
        if (itemInDifferentPlace) {
          this.hiddenFieldTarget.value = JSON.stringify(this.getIndexOfItems())
        }
      },
    })
  }

  getIndexOfItems() {
    const indexList = this.orderColumnTargets.map(orderColumnTarget => {
      const index = Array.prototype.indexOf.call(
        this.containerTarget.children,
        orderColumnTarget.parentNode
      )
      orderColumnTarget.innerText = index
      return { item_id: orderColumnTarget.dataset.partSortableItemIdValue, order: index }
    })

    return indexList
  }

  getIndexOfItem(item) {
    const parent = item.parentNode
    const index = Array.prototype.indexOf.call(parent.children, item)
    return index
  }
}
