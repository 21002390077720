import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['entry']

  toggle(event) {
    const currentEntry = event.currentTarget.closest("[data-accordion-target='entry']")
    this.entryTargets.forEach(entry => {
      if (!entry.isEqualNode(currentEntry)) {
        entry.classList.remove('open')
      }
    })
    currentEntry.classList.toggle('open')
  }
}
