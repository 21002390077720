import { Controller } from '@hotwired/stimulus'
import { useDebounce } from 'stimulus-use'
import TurboClient from '../api/TurboClient'

export default class extends Controller {
  static debounces = ['search']
  static targets = ['form', 'searchInput', 'toggleContainer']
  static values = { url: String, method: String }

  connect() {
    useDebounce(this, { wait: 200 })

    this.searchInputTarget.addEventListener('blur', () => {
      this.hideContainer()
    })

    this.searchInputTarget.addEventListener('focus', () => {
      this.showContainer()
    })
  }

  hideContainer() {
    this.toggleContainerTarget.classList.add('hidden')
  }

  showContainer() {
    this.toggleContainerTarget.classList.remove('hidden')
  }

  async search() {
    if (this.searchInputTarget.value.length === 1) {
      return
    }

    const turboClient = new TurboClient(this.formTarget, {
      method: this.methodValue,
      actionUrl: this.urlValue,
    })

    await turboClient.perform()

    this.showContainer()
  }
}
