import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    partId: Number,
  }

  changePart(event) {
    const partId = Number(event.target.value)

    if (partId !== this.partIdValue) {
      const slug = event.target.options[event.target.selectedIndex].dataset.slug
      window.location.href = `/parts/${slug}`
    }
  }

  changePartnerPart(event) {
    const partId = Number(event.target.value)

    if (partId !== this.partIdValue) {
      const slug = event.target.options[event.target.selectedIndex].dataset.slug
      window.location.href = `/partners/parts/${partId}/${slug}`
    }
  }
}
