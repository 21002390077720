/*
  <button data-controller='copy-to-clipboard-button' data-copy-to-clipboard-button-text-value='f40d03dbf5861ab790a7'>Copy</button>
*/

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { payload: String, successText: String, toastrText: String }

  connect() {
    const originalHTML = this.element.innerHTML

    this.element.addEventListener('click', () => {
      const textarea = document.createElement('textarea')
      textarea.textContent = this.payloadValue

      document.body.appendChild(textarea)

      textarea.select()
      document.execCommand('copy')
      textarea.remove()

      if (this.successTextValue) {
        this.element.innerText = this.successTextValue

        setTimeout(() => {
          this.element.innerHTML = originalHTML
        }, 2000)
      }
    })
  }

  showToaster() {
    toastr.success(this.toastrTextValue)
  }
}
