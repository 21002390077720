import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['fileInput', 'fileLabel']

  initialize() {
    const inputTextElement = this.hasFileLabelTarget
      ? this.fileLabelTarget
      : document.getElementById('file-input-text')
    this.fileInputTarget.onchange = function (event) {
      const fileName = event.target.value.split('\\').pop()
      inputTextElement.classList.add('selected-file')
      inputTextElement.textContent = fileName
    }
  }
}
