import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['nonSuperAdminCheckbox', 'baseAdmin']

  toggleSuperAdmin(event) {
    const hasBeenChecked = event.currentTarget.checked

    if (hasBeenChecked) {
      this.nonSuperAdminCheckboxTargets.forEach(checkbox => {
        checkbox.checked = true
        checkbox.disabled = true
      })
    } else {
      this.nonSuperAdminCheckboxTargets.forEach(checkbox => {
        checkbox.disabled = false
      })
    }
  }

  checkExemptCorporateAccountFromTax(event) {
    const hasBeenChecked = event.currentTarget.checked

    if (hasBeenChecked) {
      this.baseAdminTarget.checked = true
    }
  }
}
