import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['label', 'trigger']
  static values = { payload: String, successText: String }

  click() {
    this.copyPayloadToClipboard()

    if (this.successTextValue) {
      this.renderSuccessMessage()
    }
  }

  copyPayloadToClipboard() {
    const textarea = document.createElement('textarea')
    textarea.textContent = this.payloadValue

    document.body.appendChild(textarea)

    textarea.select()
    document.execCommand('copy')
    textarea.remove()
  }

  renderSuccessMessage() {
    const originalLabelText = this.labelTarget.innerText
    this.labelTarget.innerText = this.successTextValue

    setTimeout(() => {
      this.labelTarget.innerText = originalLabelText
    }, 2000)
  }
}
