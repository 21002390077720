import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['editor', 'input', 'form']
  static values = {
    height: Number,
  }

  connect() {
    this.editorTarget.style.height = `${this.heightValue}px`

    const aceEditor = ace.edit(this.editorTarget.id)
    aceEditor.getSession().setUseWrapMode(true)
    aceEditor.getSession().setTabSize(2)

    if (this.inputTarget.value) {
      aceEditor.setValue(this.inputTarget.value)
    }

    aceEditor.getSession().setMode('ace/mode/json', () => {
      aceEditor.getSession().foldAll()
      aceEditor.getSession().unfold(1)
    })

    aceEditor.on('change', () => {
      this.inputTarget.value = aceEditor.getValue()
    })
  }
}
