import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  initialize() {
    this.appendFormData = this.appendFormData.bind(this)
  }

  connect() {
    this.element.addEventListener('formdata', this.appendFormData)
  }

  disconnect() {
    this.element.removeEventListener('formdata', this.appendFormData)
  }

  appendFormData(event) {
    const invitationFormData = new FormData(this.invitationForm)
    const preInviteFormData = event.formData
    const preInviteFields = Array.from(preInviteFormData.keys())

    for (const [key, value] of invitationFormData.entries()) {
      // To prevent fields like _method and authenticity_token from getting overwritten
      if (!preInviteFields.includes(key)) {
        preInviteFormData.append(key, value)
      }
    }
  }

  // The form resposible for actually creating the invites
  get invitationForm() {
    return this.element
      .closest('.pre-invite-index')
      .querySelector('form.dashboard-pre-invite-submission-form')
  }
}
