import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  closeModal(event) {
    if (event.target === this.modalBackDrop) {
      event.preventDefault()
      this.element.close('cancel')
    }
  }

  get modalBackDrop() {
    const modalBackDropElement = document.querySelector('.modal-backdrop')
    return modalBackDropElement
  }
}
