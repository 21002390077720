import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    websocketUrl: String,
    serialNumbers: Array,
    authToken: String,
  }

  connect() {
    this.setWebsocketListeners()
  }

  setWebsocketListeners() {
    this.websocket = new WebSocket(this.websocketUrlValue, this.authTokenValue)
    this.websocket.onopen = async () => {
      console.info('Connected to MachineCloud browser socket.')
    }

    this.websocket.onmessage = async message => {
      const data = JSON.parse(message.data)
      const statusElement = document.getElementById(data.data.serialNumber)
      if (statusElement) {
        statusElement.replaceChildren()
        if (data.data.connectivity === false) {
          this.clone = this.machineOfflineStatus.content.cloneNode(true)
          statusElement.appendChild(this.clone)
        } else if (data.data.status) {
          const messageTemplate = document.getElementById(
            `machine-${data.data.status}-deploy-status`
          )
          this.clone = messageTemplate.content.cloneNode(true)
          statusElement.appendChild(this.clone)
        }
      }
      return false
    }

    this.websocket.onerror = error => console.error(error)

    this.websocket.onclose = async event => {
      if (event.code !== 1000) {
        console.warn(
          'Disconnected from MachineCloud browser socket. Reconnecting in 5 seconds...',
          event
        )
      }
    }
  }

  disconnect() {
    // set status code to 1000 when expected websocket close: https://www.rfc-editor.org/rfc/rfc6455.html#section-7.4
    this.websocket.close(1000)
  }

  get readyToDeployHtml() {
    return document.getElementById('machine-ready-to-deploy-status')
  }
  get machineOfflineStatus() {
    return document.getElementById('machine-offline-deploy-status')
  }
}
