// this controller removes items in a list when a button within a wrapper div triggers
// a remove action.
// a custom class can be applied to the parent div when there are no longer any targets left in the list

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['elementToRemove', 'elementToRemoveOnAnimationEnd']
  static values = {
    parentElementId: String,
  }
  static classes = ['onParentRemove']

  connect() {
    if (this.hasElementToRemoveOnAnimationEndTarget) {
      this.elementToRemoveOnAnimationEndTarget.addEventListener('animationend', () => {
        this.elementToRemoveOnAnimationEndTarget.remove()
        this.removeParent()
      })
    }
  }

  remove(event) {
    const elementToRemove = event.currentTarget.closest(
      `.${this.elementToRemoveTarget.classList.value}`
    )
    elementToRemove.remove()
    this.removeParent()
  }

  removeParent() {
    if (!this.hasElementToRemoveTarget && this.parentElementIdValue) {
      const parentElementToRemove = document.getElementById(this.parentElementIdValue)
      parentElementToRemove.classList.add(this.onParentRemoveClass)
    }
  }
}
