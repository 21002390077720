import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['checkbox']

  connect() {
    this.updateButtonTextToListSelectedRoles()
  }

  updateButtonTextToListSelectedRoles() {
    const maxRoles = 1
    const selectedRoles = this.selectedRoleNames()
    const nMore = selectedRoles.length - maxRoles
    const hasMore = nMore > 0
    let label = selectedRoles.slice(0, maxRoles).join(', ')

    if (hasMore) {
      label += ` +${nMore} more`
    }

    this.button.innerHTML = label
  }

  get button() {
    return this.element.querySelector('.click-dropdown-toggle')
  }

  selectedRoleNames() {
    return this.checkboxTargets
      .filter(checkbox => checkbox.checked)
      .map((checkbox) => {
        return this.element.querySelector(`label[for="${checkbox.id}"]`).innerText
      })
  }
}
