import { Controller } from '@hotwired/stimulus'
import { useWindowResize } from 'stimulus-use'
import { breakpoints } from '../constants/Layout'

export default class extends Controller {
  static targets = ['disable', 'toggleClass']
  static values = {
    breakpointLabel: { type: String, default: 'navMainLarge' },
  }
  static classes = ['toggle']

  connect() {
    useWindowResize(this)
  }

  windowResize({ width }) {
    this.isMobileValue = width <= breakpoints[this.breakpointLabelValue]
    this.disableElements()
    this.toggleClassValue()
  }

  disableElements() {
    this.disableTargets.forEach(element => {
      if (this.isMobileValue) {
        element.setAttribute('disabled', true)
      } else {
        element.removeAttribute('disabled')
      }
    })
  }

  toggleClassValue() {
    this.toggleClassTargets.forEach(el =>
      el.classList.toggle(this.toggleClass, !this.isMobileValue)
    )
  }
}
