import Glider from 'glider-js'
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { options: Object }

  connect() {
    this.glider = new Glider(this.element, this.optionsValue)
  }

  disconnect() {
    this.glider.destroy()
  }
}
