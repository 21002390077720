import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  initialize() {
    const turboFrameElement = this.element.closest('turbo-frame[src]')
    if (turboFrameElement) {
      turboFrameElement.src = ''
    }
  }
}
