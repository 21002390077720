import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['toggleable']

  connect() {
    this.toggle()
  }

  toggle() {
    this.toggleableTargets.forEach(toggleableTarget => {
      const checkboxElements = Array.from(toggleableTarget.querySelectorAll('input[type="checkbox"]'))
      const numberInputElements = Array.from(toggleableTarget.querySelectorAll('input[type="number"]'))
      const activeCheckbox = checkboxElements.find(checkboxElement => checkboxElement.checked)
      const activeNumberInput = numberInputElements.find(numberInputElement => numberInputElement.value.length > 0)
      const circleIcon = toggleableTarget.previousElementSibling.querySelector('.fa-circle')

      if ((toggleableTarget.contains(activeCheckbox) || toggleableTarget.contains(activeNumberInput))) {
        circleIcon.classList.remove('hidden')
      } else {
        circleIcon.classList.add('hidden')
      }
    })
  }
}
