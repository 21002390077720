// Depends on modal_controller.js
// modal_controller is this controllers 'outlet'
// Use Stimulus Outlets Api - https://stimulus.hotwired.dev/reference/outlets
/*

<button
  data-controller="modal-trigger"
  data-action="click->modal-trigger#openModal"
  data-modal-trigger-modal-outlet="#unique_selector_id" OR any CSS selector of your choice
>
  Click here
</button>

<div data-controller='modal' id="selector_id" class='generic-modal'>
  <div class='modal-content'>
    <h1>Modal Header</h1>
    <p>Hello world</p>
  </div>
</div>

*/

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static outlets = ['modal']

  openModal() {
    if (this.hasModalOutlet) {
      this.modalOutlet.reset()
      this.modalOutlet.open()
    }
  }
}
