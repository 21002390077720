import { Controller } from '@hotwired/stimulus'
import TurboClient from '../api/TurboClient'
import { handleFileDownload } from '../api/httpClient'

// Used on the form displayed in the package download modal
export default class extends Controller {
  static targets = ['form', 'submitButton', 'modal']

  static values = {
    contentMime: String,
    successButton: String,
  }

  async submit(event) {
    event.preventDefault()

    const turboClient = new TurboClient(this.formTarget)

    const { response } = await turboClient.perform()

    const contentDispositionHeader = response.headers.get('content-disposition')

    // Handle custom case when the download is handled
    if (contentDispositionHeader?.startsWith('attachment')) {
      const zip_data = await response.blob()
      handleFileDownload(zip_data, this.contentMimeValue, null, contentDispositionHeader)

      // reset form fields so that modal can be used again
      // error elements have no targets as they're dynamically injected
      this.formTarget.querySelectorAll('.has-error').forEach(el => el.remove())
      this.formTarget.reset()

      // enable back the submit button
      if (this.hasSubmitButtonTarget) {
        this.submitButtonTarget.disabled = false
        this.submitButtonTarget.value = this.successButtonValue
      }

      // close the modal if needed
      if (this.hasModalTarget) this.modalTarget.hidden = true
    }
  }
}
