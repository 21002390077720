import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['billingAddressOverview', 'billingAddressForm']
  static values = { hasFormErrors: Boolean }

  connect() {
    if (this.hasFormErrorsValue) {
      this.showForm()
    }
  }

  showForm() {
    this.billingAddressOverviewTarget.classList.add('hidden')
    this.billingAddressFormTarget.classList.remove('hidden')
  }
}
