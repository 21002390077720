import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    window.addEventListener('beforeunload', this.onCloseEventListener)
  }

  disconnect() {
    window.removeEventListener('beforeunload', this.onCloseEventListener)
  }

  onCloseEventListener(event) {
    event.returnValue = '' // For Chrome
    event.preventDefault() // For other browsers
  }
}
