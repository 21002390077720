import { Controller } from '@hotwired/stimulus'
import { breakpoints } from '../constants/Layout'

export default class extends Controller {
  static targets = ['headerFilter', 'list']

  initialize() {
    this.initializeViewFromHash = this.initializeViewFromHash.bind(this)
    this.updateViewOnResize = this.updateViewOnResize.bind(this)
  }

  connect() {
    this.initializeViewFromHash()
    this.setActiveBackgroundForHeaderFilter()
    this.initializeIndustryLogos()

    window.addEventListener('resize', this.updateViewOnResize)

    // Needed for when the user clicks on an anchor link in the same page (available in footer, for example)
    window.addEventListener('hashchange', this.initializeViewFromHash)
  }

  disconnect() {
    window.removeEventListener('resize', this.updateViewOnResize)
    window.removeEventListener('hashchange', this.initializeViewFromHash)
  }

  initializeViewFromHash() {
    const categoryName = window.location.hash.split('#')[1]
    if (categoryName) {
      this.initializeFilterFromCategoryName(categoryName)
      this.initializeMobileFilterFromCategoryName(categoryName)
      this.element.scrollIntoView()
    }
  }

  initializeFilterFromCategoryName(name) {
    const button = this.element.querySelector(`button[data-name="${name}"]`)

    if (button) {
      button.click()
    }
  }

  initializeMobileFilterFromCategoryName(name) {
    const select = this.element.querySelector('#mobile_filter_by_category')
    if (!select) return

    const option = select.querySelector(`option[data-name="${name}"]`)
    if (!option) return

    select.value = option.value
  }

  updateViewOnResize() {
    this.setActiveBackgroundForHeaderFilter()

    if (window.innerWidth <= breakpoints.small) {
      this.setActiveOptionOnSelectBasedOnActiveFilter()
      this.filterListFromSelectBasedOnActiveOption()
    } else {
      this.setActiveFilterBasedOnActiveOption()
    }
  }

  initializeIndustryLogos() {
    const activeIndustryFilter = this.element.querySelector('.active [data-type="industry"]')
    const industry = activeIndustryFilter.dataset.name
    this.showIndustryLogos(industry)
  }

  filterList(event) {
    const element = event.target
    const codes = element.dataset.codes.split(',')
    const type = element.dataset.type

    if (type === 'industry') {
      this.showIndustryLogos(element.dataset.name)
    }

    this.activateListFilterButton(event)
    this.filterListItems(codes)
    this.element.scrollIntoView()
  }

  filterListFromSelect(event) {
    const element = event.target
    const codes = element.value.split(',')
    const option = element[element.selectedIndex]

    this.activeOption = option
    this.activeFilter = null

    this.filterListItems(codes)
  }

  filterListFromSelectBasedOnActiveOption() {
    const select = this.element.querySelector('#mobile_filter_by_category')
    if (!select) return

    const codes = select.value.split(',')

    this.filterListItems(codes)
  }

  filterHeader(event) {
    this.activateHeaderFilterButton(event)
    this.filterListItemsBasedOnActiveFilter(event)
    this.changeView(event)
  }

  filterListItems(codes) {
    if (codes[0] === '') {
      return this.showAllListItems()
    }

    this.listTarget.querySelectorAll('[data-code]').forEach(item => {
      if (codes.includes(item.dataset.code)) {
        item.classList.remove('hidden')
      } else {
        item.classList.add('hidden')
      }
    })
  }

  showIndustryLogos(industry) {
    this.element.querySelectorAll('.industry-logos').forEach(item => {
      if (item.dataset.industry === industry) {
        item.classList.remove('hidden')
      } else {
        item.classList.add('hidden')
      }
    })
  }

  activateListFilterButton(event) {
    const element = event.target
    const list = element.closest('ul')

    this.activeFilter = element
    this.activeOption = null

    element
      .closest('ul')
      .querySelectorAll('li')
      .forEach(item => item.classList.remove('active'))
    element.closest('li').classList.add('active')

    this.setActiveBarForListFilter(list)
  }

  activateHeaderFilterButton(event) {
    const element = event.target
    this.headerFilterTargets.forEach(item => item.classList.remove('active'))
    element.classList.add('active')

    this.setActiveBackgroundForHeaderFilter()
  }

  setActiveBackgroundForHeaderFilter() {
    const parent = this.element.querySelector('.section--applications__header__filters')
    const activeBackground = parent.querySelector('.active-background')
    const activeElement = parent.querySelector('.active')

    const activeBackgroundWidth = activeElement.offsetWidth
    const activeBackgroundLeft = activeElement.offsetLeft

    activeBackground.style.width = `${activeBackgroundWidth}px`
    activeBackground.style.left = `${activeBackgroundLeft}px`
  }

  showAllListItems() {
    this.listTarget.querySelectorAll('[data-code]').forEach(item => item.classList.remove('hidden'))
  }

  changeView(event) {
    const element = event.target
    const targetView = element.dataset.targetView

    this.element.querySelectorAll('[data-view]').forEach(item => {
      if (item.dataset.view === targetView) {
        item.classList.remove('hidden')
      } else {
        item.classList.add('hidden')
      }
    })
  }

  filterListItemsBasedOnActiveFilter(event) {
    const element = event.target
    const targetView = element.dataset.targetView
    const targetViewElement = this.listTarget.querySelector(`[data-view="${targetView}"]`)
    const activeFilter = targetViewElement.querySelector('.active button')
    const codes = activeFilter.dataset.codes.split(',')
    this.filterListItems(codes)
  }

  setActiveBarForListFilter(list) {
    const barElement = list.querySelector('.active-bar')
    const activeElement = list.querySelector('.active')

    const listItemHeight = activeElement.offsetHeight
    const listItemTop = activeElement.offsetTop

    barElement.style.height = `${listItemHeight}px`
    barElement.style.top = `${listItemTop}px`
  }

  /**
   * Transfers the active filter to the select element (only visible on mobile),
   * so that on resize the active filter is kept.
   */
  setActiveOptionOnSelectBasedOnActiveFilter() {
    if (this.activeFilter) {
      this.initializeMobileFilterFromCategoryName(this.activeFilter.dataset.name)
    }
  }

  /**
   * Transfers the active option to the filter element (only visible on desktop),
   * so that on resize the active filter is kept.
   */
  setActiveFilterBasedOnActiveOption() {
    if (this.activeOption) {
      this.initializeFilterFromCategoryName(this.activeOption.dataset.name)
    }
  }
}
