import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['tochange', 'source']
  static values = { current: Number }

  connect() {
    this.decrease()
  }

  decrease() {
    if (!this.hasSourceTarget) return

    const enteredValue = parseFloat(this.sourceTarget.value)
    this.tochangeTarget.innerText =
      isNaN(enteredValue) || enteredValue < 0
        ? this.currentValue.toFixed(2)
        : Math.max(this.currentValue - enteredValue, 0).toFixed(2)
  }
}
