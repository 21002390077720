// This doesn't actually hide or reveal tab sections. It just adds and removes the 'data-active-section' and 'data-active-button' attributes. It's up to you to write the CSS.

/*

<style>
  div[data-tab-section] {
    display: none;
  }

  div[data-active-section] {
    display: initial;
  }

  button[data-active-button] {
    text-decoration: underline;
  }
</style>

<div data-controller='tabs'>
  <button data-active-button data-tabs-target='button' data-tab-button='dogs'>Dogs</button>
  <button data-tabs-target='button' data-tab-button='cats'>Cats</button>

  <div data-active-section data-tab-section='dogs'>
    <h2>Cats</h2>
  </div>

  <div data-tab-section='cats'>
    <h2>Dogs</h2>
  </div>
</div>

*/

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['button', 'section']

  connect() {
    this.buttonTargets.forEach((button) => {
      button.addEventListener('click', () => {
        this.element.querySelector('[data-active-section]')?.removeAttribute('data-active-section')
        this.element.querySelector(`[data-tab-section='${button.dataset.tabButton}']`).dataset.activeSection = 'true'

        this.element.querySelector('[data-active-button]')?.removeAttribute('data-active-button')
        button.dataset.activeButton = 'true'
      });
    })
  }
}
