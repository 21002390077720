import { Controller } from '@hotwired/stimulus'
import { FetchRequest } from '@rails/request.js'

/**
 * Performs a request to a custom URL, with a custom verb, using the form data.
 * Useful for server-side rendering modals, menus, etc, that depend on the original form data.
 */
export default class extends Controller {
  static targets = ['form']
  static classes = ['loading']

  initialize() {
    this.requestDefaultOptions = {
      responseKind: 'turbo-stream',
    }
  }

  request(e) {
    const { method, url } = e.target.dataset
    const formData = new FormData(this.formTarget)

    if (formData.has('_method')) formData.set('_method', method)

    const options = this.requestOptions({ body: formData })
    const request = new FetchRequest(method, url, options)

    if (this.hasLoadingState(e)) {
      this.withLoadingState(e, request.perform())
    } else {
      request.perform()
    }
  }

  requestOptions(options) {
    return {
      ...this.requestDefaultOptions,
      ...options,
    }
  }

  withLoadingState(event, request) {
    this.showLoadingState(event)

    return request.then(response => {
      this.hideLoadingState(event)
      return response
    })
  }

  showLoadingState(event) {
    if (this.hasLoadingClass) event.target.classList.add(this.loadingClass)

    this.toggleDisable(event, true)
  }

  hideLoadingState(event) {
    if (this.hasLoadingClass) event.target.classList.remove(this.loadingClass)

    this.toggleDisable(event, false)
  }

  hasLoadingState(event) {
    return this.hasLoadingClass || event.target.dataset.disableOnLoading
  }

  toggleDisable(event, disabled) {
    const element = event.target

    if (element.dataset.disableOnLoading) {
      element.disabled = disabled
    }
  }
}
