import { Controller } from '@hotwired/stimulus'
import Editor from '@toast-ui/editor'
import TurboClient from '../api/TurboClient'
import {
  ButtonMarkdownTemplate,
  AccordionMarkdownTemplate,
  YoutubeVideoMarkdownTemplate,
  VimeoVideoMarkdownTemplate,
  NoTocMarkdownTemplate,
  NoTocMarkdownTemplateFormatted,
  ImageWidthMarkdownTemplate,
} from '../constants/Markdown'
import Prism from 'prismjs'
import 'prismjs/components/prism-python.min'
import codeSyntaxHighlight from '@toast-ui/editor-plugin-code-syntax-highlight'
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  static targets = ['editor', 'contentInput', 'form']
  static values = {
    previewActionUrl: String,
    type: String,
    imageUploader: String,
    previewStyle: String, // tab (default), vertical, none
  }

  initialize() {
    this.imageUploaderPaths = {
      cms: '/cms/images',
    }
  }

  connect() {
    const markdownModeTip =
      '(tip: use the Markdown mode of the editor to have more control over the source code)'

    this.editor = new Editor({
      el: this.editorTarget,
      height: null,
      minHeight: '600px',
      initialEditType: this.typeValue || 'wysiwyg',
      hideModeSwitch: this.hasTypeValue,
      previewStyle: this.previewStyleValue || 'vertical',
      initialValue: this.contentInputTarget.value,
      usageStatistics: false,
      events: {
        change: this.save.bind(this),
        // adjust double line breaks with custom markdown
        // also account for formatted tags that are escape when switching modes
        beforeConvertWysiwygToMarkdown: markdown => {
          const adjustedMarkdown = markdown.replaceAll(
            `\n\n${NoTocMarkdownTemplateFormatted}`,
            `\n ${NoTocMarkdownTemplate}`
          )
          return adjustedMarkdown
        },
      },
      plugins: [[codeSyntaxHighlight, { highlighter: Prism }], this.customPlugin.bind(this)],

      toolbarItems: [
        [
          'heading',
          this.makeToolbarItem(
            'noToc',
            `Insert directly below a Heading to exclude it from the Table of Contents ${markdownModeTip}`,
            'insertNoToc',
            'far fa-not-equal'
          ),
        ],
        ['bold', 'italic', 'strike'],
        ['hr', 'quote'],
        ['ul', 'ol', 'task', 'indent', 'outdent'],
        [
          'image',
          this.makeToolbarItem(
            'imageWidth',
            `Insert after an image to modify its width ${markdownModeTip}`,
            'insertImageWidth',
            'far fa-arrows-h'
          ),
        ],
        ['table', 'link'],
        ['code', 'codeblock'],
        this.customToolbarItems(),
        ['scrollSync'],
      ],
      hooks: this.getHooks(),
    })
  }

  save() {
    if (this.editor) {
      this.contentInputTarget.value = this.editor.getMarkdown()
    }
  }

  async preview(event) {
    const buttonElement = event.currentTarget
    const originalText = buttonElement.textContent
    buttonElement.textContent = 'Previewing...'
    buttonElement.disabled = true

    const turboClient = new TurboClient(this.formTarget, {
      method: 'PUT',
      actionUrl: this.previewActionUrlValue,
      redirectNewWindow: true,
    })

    await turboClient.perform()

    buttonElement.textContent = originalText
    buttonElement.disabled = false
  }

  customPlugin() {
    return {
      markdownCommands: this.customCommands(),
      wysiwygCommands: this.customCommands(),
      toHTMLRenderers: {
        // Allow additional html tags
        htmlInline: {
          sub(_, { entering }) {
            return { type: entering ? 'openTag' : 'closeTag', tagName: 'sub' }
          },
          sup(_, { entering }) {
            return { type: entering ? 'openTag' : 'closeTag', tagName: 'sup' }
          },
        },
      },
    }
  }

  customCommands() {
    return {
      insertButton: this.insertMarkdownText(ButtonMarkdownTemplate),
      insertAccordion: this.insertMarkdownText(AccordionMarkdownTemplate),
      insertYoutubeVideo: this.insertMarkdownText(YoutubeVideoMarkdownTemplate),
      insertVimeoVideo: this.insertMarkdownText(VimeoVideoMarkdownTemplate),
      insertNoToc: this.insertMarkdownText(NoTocMarkdownTemplate),
      insertImageWidth: this.insertMarkdownText(ImageWidthMarkdownTemplate),
    }
  }

  insertMarkdownText(text) {
    return (_payload, state, dispatch) => {
      const transaction = state.tr
      const textNode = state.schema.text(text)
      transaction.replaceSelectionWith(textNode)
      dispatch(transaction.scrollIntoView())
      this.editor.focus()
      return true
    }
  }

  makeToolbarItem(name, tooltip, command, className) {
    return {
      name: name,
      tooltip: tooltip,
      command: command,
      className: `toastui-editor-toolbar-icons toastui-editor-toolbar-icons-custom ${className}`,
    }
  }

  customToolbarItems() {
    const previewableTip = '(tip: after adding, preview on the front by clicking "Preview")'
    const items = [
      {
        tooltip: `Insert Button ${previewableTip}`,
        name: 'Button',
        class: 'far fa-rectangle-wide',
      },
      {
        tooltip: `Insert Accordion ${previewableTip}`,
        name: 'Accordion',
        class: 'far fa-chevron-square-down',
      },
      {
        tooltip: `Insert Youtube Video ${previewableTip}`,
        name: 'YoutubeVideo',
        class: 'fab fa-youtube',
      },
      {
        tooltip: `Insert Vimeo Video ${previewableTip}`,
        name: 'VimeoVideo',
        class: 'fab fa-vimeo',
      },
    ]

    return items.map(item => {
      return this.makeToolbarItem(item.name, item.tooltip, `insert${item.name}`, item.class)
    })
  }

  uploadImage(blob) {
    let formData = new FormData()
    formData.append('image', blob, blob.name)

    const request = new FetchRequest('POST', this.imageUploaderUrl, {
      body: formData,
    })

    this.addImagePopupOkButton.disabled = true

    return request.perform().then(response => {
      this.addImagePopupOkButton.disabled = false
      return response.json
    })
  }

  addImageBlobHook(blob, callback) {
    this.uploadImage(blob)
      .then(response => {
        const errors = response.errors
        if (errors && Object.keys(errors).length > 0) {
          console.error(response)

          const allErrors = Object.values(errors).flat()

          if (allErrors.length > 0) {
            window.alert(allErrors.join(', '))
          }
          return
        }

        callback(response.url)
      })
      .catch(error => {
        console.error(error)
      })
  }

  getHooks() {
    const hooks = {}

    if (this.hasValidImageUploader) {
      hooks.addImageBlobHook = this.addImageBlobHook.bind(this)
    }

    return hooks
  }

  get validImageUploaders() {
    return Object.keys(this.imageUploaderPaths)
  }

  get hasValidImageUploader() {
    return this.hasImageUploaderValue && this.validImageUploaders.includes(this.imageUploaderValue)
  }

  get imageUploaderUrl() {
    return this.imageUploaderPaths[this.imageUploaderValue]
  }

  get addImagePopupOkButton() {
    return this.element.querySelector(
      '.toastui-editor-popup-add-image .toastui-editor-button-container > button.toastui-editor-ok-button'
    )
  }
}
