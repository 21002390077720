import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['container', 'searchInput']

  toggleSearch() {
    this.containerTarget.classList.toggle(
      'mobile-resource-header-search-active'
    )
  }

  clearSearchInput() {
    this.searchInputTarget.value = ''
  }

  expandHeader() {
    this.containerTarget.classList.toggle('mobile-resource-header-expanded')
  }
}
