import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['partnerFeatureSelect']
  static values = {
    partnerFeaturePresent: Boolean,
  }
  submit(event) {
    if (this.partnerFeatureRemoved()) {
      let confirmed = confirm(
        'Are you sure? Removing the partnership type will delete the partnership.'
      )
      if (!confirmed) {
        event.preventDefault()
      }
    }
  }

  partnerFeatureRemoved() {
    return this.partnerFeaturePresentValue && this.partnerFeatureSelectTarget.value == ''
  }
}
