// Toggleable nested form for single attributes
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['addButton', 'template', 'form']

  // Show the form
  add_association() {
    this.addButtonTarget.insertAdjacentHTML(
      'beforebegin',
      this.templateTarget.innerHTML
    )
    this.addButtonTarget.classList.add('hidden')
  }

  // Hide the form
  remove_association() {
    this.formTarget.remove()
    this.addButtonTarget.classList.remove('hidden')
  }
}
