import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    offset: Number, // default is 0
  }

  initialize() {
    this.autogrow = this.autogrow.bind(this)
  }

  connect() {
    this.element.style.overflow = 'hidden'

    this.onResize = this.autogrow

    this.autogrow()

    this.element.addEventListener('input', this.autogrow)
    window.addEventListener('resize', this.onResize)
  }

  disconnect() {
    window.removeEventListener('resize', this.onResize)
  }

  autogrow() {
    this.element.style.height = 'auto' // Force re-print before calculating the scrollHeight value.
    this.element.style.height = `${this.element.scrollHeight - this.offsetValue}px`
  }
}
