import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['selectTag', 'categoryCodeHiddenField', 'subCategoryCodeHiddenField']

  initialize() {
    this.selectListener = this.selectListener.bind(this)
  }

  connect() {
    this.selectTagTarget.addEventListener('change', this.selectListener)
  }

  disconnect() {
    this.selectTagTarget.removeEventListener('change', this.selectListener)
  }

  selectListener() {
    const optionElements = Array.from(this.selectTagTarget.getElementsByTagName('option'))
    const selectedOptionElement = optionElements.find(element => element.selected)

    if (selectedOptionElement) {
      const [categoryCode, subCategoryCode] = selectedOptionElement.value.split('-')

      if (categoryCode && subCategoryCode) {
        this.categoryCodeHiddenFieldTarget.value = categoryCode
        this.subCategoryCodeHiddenFieldTarget.value = subCategoryCode
      } else {
        this.categoryCodeHiddenFieldTarget.value = null
        this.subCategoryCodeHiddenFieldTarget.value = null
      }

      this.element.requestSubmit()
    }
  }
}
