import { Controller } from '@hotwired/stimulus'
import scrollnav from 'scrollnav'

export default class extends Controller {
  static targets = ['insertTarget', 'content']

  connect() {
    scrollnav.init(this.contentTarget, {
      insertTarget: this.insertTargetTarget,
      insertLocation: 'prepend',
      sections: 'h2:not(.scroll-nav-exclude)',
    })
    this.timeoutHandle = setTimeout(() => {
      scrollnav.updatePositions()
    }, 100)
  }

  disconnect() {
    this.insertTargetTarget.innerHTML = ''

    if (this.timeoutHandle) {
      clearTimeout(this.timeoutHandle)
    }
  }
}
