import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    parentSelector: String,
  }

  initialize() {
    this.observer = new window.MutationObserver(this.callback())
  }

  connect() {
    this.observer.observe(this.element, {
      childList: true,
    })
  }

  disconnect() {
    this.observer.disconnect()
  }

  callback() {
    return mutationsList => {
      for (let mutation of mutationsList) {
        if (mutation.type === 'childList') {
          const parent = mutation.target.closest(this.parentSelectorValue)
          if (parent && this.element.children.length === 0) {
            parent.remove()
          }
        }
      }
    }
  }
}
