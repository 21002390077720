import { Controller } from '@hotwired/stimulus'
import { createConversion } from '../api/AbTestConversions/Service'

export default class extends Controller {
  static values = {
    experiment: String,
  }

  convert() {
    createConversion(this.experimentValue)
  }
}
