import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'image',
    'viewPartLink',
    'addToCartButton',
    'nameLink',
    'partNumber',
    'price',
    'availability'
  ]

  initialize() {
    this.parts = JSON.parse(this.element.getAttribute('data-parts'))
  }

  changePart(event) {
    const partId = event.target.value
    const part = this.parts[partId]

    this.imageTarget.src = part.thumbnail_image_path
    this.viewPartLinkTarget.href = part.full_url
    this.addToCartButtonTarget.setAttribute('data-part-id', part.part_id)
    this.nameLinkTarget.textContent = part.part_name
    this.nameLinkTarget.href = part.full_url
    this.nameLinkTarget.setAttribute('data-content', part.part_name)
    this.partNumberTarget.textContent = part.part_number
    this.priceTarget.textContent = part.formatted_unit_price

    const statusAvailability = JSON.parse(part.status_availability_json)
    this.availabilityTarget.textContent = statusAvailability.text
    this.availabilityTarget.className = `part-card-availability ${statusAvailability.className}`
  }

  changePartnerPart(event) {
    const partId = event.target.value
    const part = this.parts[partId]

    this.imageTarget.src = part.thumbnail_image_path
    this.viewPartLinkTarget.href = part.full_url
    this.addToCartButtonTarget.setAttribute('data-part-id', part.part_id)
    this.nameLinkTarget.textContent = part.part_name
    this.nameLinkTarget.href = part.full_url
    this.nameLinkTarget.setAttribute('data-content', part.part_name)
    this.partNumberTarget.textContent = part.part_number
    this.priceTarget.textContent = part.formatted_unit_price
  }
}
