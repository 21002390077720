import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['activeLink']

  initialize() {
    this.clickListener = this.clickListener.bind(this)
  }

  connect() {
    this.element.addEventListener('click', this.clickListener)
  }

  disconnect() {
    this.element.removeEventListener('click', this.clickListener)
  }

  clickListener(event) {
    if (event.target.classList.contains('inactive')) {
      const activeTarget = this.activeLinkTargets.find(target =>
        target.classList.contains('active')
      )
      const inactiveTarget = this.activeLinkTargets.find(target =>
        target.classList.contains('inactive')
      )

      activeTarget.classList.replace('active', 'inactive')
      inactiveTarget.classList.replace('inactive', 'active')
    }
  }
}
