import { Controller } from '@hotwired/stimulus'
import { breakpoints } from '../constants/Layout'

export default class extends Controller {
  connect() {
    this.setPaddingTop()
  }

  setPaddingTop() {
    document.getElementById('main-content').style.paddingTop = this.navBarHeight
    this.setSecondaryNavTop()
  }

  setSecondaryNavTop() {
    this.stickNavItems?.forEach(stickNavTarget => {
      stickNavTarget.style.top = this.navBarHeight
    })
  }

  get navBarHeight() {
    let height = document.getElementById('nav-container').offsetHeight
    const floatingNav = document.querySelector('.main-nav-floating')

    if (floatingNav) {
      if (window.innerWidth > breakpoints.navMainLarge) {
        const mainNavHeight = document.querySelector('.main-nav').offsetHeight
        height -= mainNavHeight
      } else {
        const mobileNavHeight = document.querySelector('.main-nav-mobile').offsetHeight
        height -= mobileNavHeight
      }
    }

    return `${height}px`
  }

  get stickNavItems() {
    return document.querySelectorAll("[data-main-content-target='stickyNav']")
  }
}
