import { Controller } from '@hotwired/stimulus'
import ActivityLogService from '../api/ActivityLog/Service'

export default class extends Controller {
  static targets = ['searchInput']
  static values = { location: String }

  trackSearchQuery() {
    const query = this.searchInputTarget.value
    const searchLocation = this.locationValue
    if (query.trim().length > 0) {
      ActivityLogService.trackSearchQuery({ query, searchLocation })
    }
  }
}
