import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['toggleElement']
  static classes = ['name']

  toggle(event) {
    const element = this.hasToggleElementTarget ? this.toggleElementTarget : event.currentTarget
    const nameClass = event.currentTarget.dataset.removeCssClassNameClass || this.nameClass
    element.classList.remove(nameClass)
  }
}
