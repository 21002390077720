import { Controller } from '@hotwired/stimulus'
import Prism from 'prismjs'
import 'prismjs/components/prism-python.min'
import 'prismjs/plugins/line-numbers/prism-line-numbers.min'

export default class extends Controller {
  connect() {
    this.element.querySelectorAll("code[class*='language-']").forEach(element => {
      Prism.highlightElement(element)
    })
  }
}
