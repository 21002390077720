import { Controller } from '@hotwired/stimulus'
export default class extends Controller {
  static targets = ['button', 'content']

  initialize() {
    this.buttonTarget.classList.add('selected')
    this.contentTarget.classList.add('selected')
  }

  select(event) {
    this.removeSelections()

    const button = event.currentTarget
    button.classList.add('selected')

    const tabKey = button.getAttribute('data-tab-key')
    const selectedTabContentElement = document.querySelector(`.step-tabs-content.${tabKey}`)
    selectedTabContentElement.classList.add('selected')
  }

  selectNext(event) {
    this.removeSelections()

    const button = event.currentTarget

    const nextTabKey = button.getAttribute('data-next-tab-key')

    const nextTabButtonElement = document.querySelector(`.step-tabs-button.${nextTabKey}`)
    const nextTabContentElement = document.querySelector(`.step-tabs-content.${nextTabKey}`)

    nextTabButtonElement.classList.add('selected')
    nextTabContentElement.classList.add('selected')

    window.scrollTo(0, 0)
  }

  removeSelections() {
    this.removeSelectedClass(this.buttonTargets)
    this.removeSelectedClass(this.contentTargets)
  }

  removeSelectedClass(elements) {
    elements.forEach(element => {
      const elementIncludesSelectedClass = element.classList.contains('selected')

      if (elementIncludesSelectedClass) {
        element.classList.remove('selected')
      }
    })
  }
}
