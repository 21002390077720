import { Controller } from '@hotwired/stimulus'

/**
 * Takes a search input and opens a window in a new tab based on that input
 * Example:
 * <%= content_tag :div, data: { controller: 'search-to-route', 'search-to-route-path-value': '/resources/search/' } do %>
 *   <%= text_field_tag 'query', '', data: { 'search-to-route-target': 'searchBar', action: 'keydown->search-to-route#keyPressed' }, placeholder: 'Search' %>
 *   <%= button_tag data: { action: 'click->search-to-route#changeRoute' } do %>
 *     <i class='far fa-search'></i>
 *   <% end %>
 * <% end %>
 */

export default class extends Controller {
  static values = {
    path: String, // This is the path that the search term will be appended to
  }
  static targets = ['searchBar']

  changeRoute(event) {
    event.preventDefault()
    let url = this.pathValue + (this.searchBarTarget.value || '')
    window.open(url, '_blank').focus()

  }

  keyPressed(event) {
    // If enter was pressed, change route
    if (event.keyCode == 13) {
      this.changeRoute(event)
    }
  }
}
