// https://developers.google.com/youtube/iframe_api_reference

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { videoids: String, modalId: String }

  connect() {
    this.youtubeIds = JSON.parse(this.videoidsValue)
    const intervalId = setInterval(() => {
      if (window.youTubeIframeAPIReady) {
        this.allPlayers = this.youtubeIds.map(videoItem => {
          const currentTargetDiv = document.getElementById(`gallery-video-${videoItem.id}`)
          return new YT.Player(currentTargetDiv, {
            width: '100%',
            videoId: videoItem.youtube_id,
          })
        })
        clearInterval(intervalId)
      }
    }, 50)

    if (this.modalIdValue) {
      $(`#${this.modalIdValue}`).on('hidden.bs.modal', () => {
        this.stopVideos()
      })
    }
  }

  stopVideos() {
    this.allPlayers.forEach(video => {
      video.stopVideo()
    })
  }
}
