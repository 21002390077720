import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['categoryId', 'subcategoryId', 'form', 'tabCategory', 'tabSubcategory']

  select(event) {
    const categoryId = event.currentTarget.dataset.categoryId
    const subcategoryId = event.currentTarget.dataset.subcategoryId
    const formActionUrl = event.currentTarget.dataset.formActionUrl

    if (this.hasCategoryIdTarget) {
      this.categoryIdTarget.value = categoryId ? categoryId : ''
    }

    if (this.hasSubcategoryIdTarget) {
      this.subcategoryIdTarget.value = subcategoryId ? subcategoryId : ''
    }
    if (this.hasFormTarget) {
      this.formTarget.action = formActionUrl
    }

    this.tabCategoryTargets.forEach(tab =>
      tab.dataset.categoryId === categoryId
        ? tab.classList.add('active')
        : tab.classList.remove('active')
    )

    this.tabSubcategoryTargets.forEach(tab => {
      tab.dataset.categoryId === categoryId && tab.dataset.subcategoryId === subcategoryId
        ? tab.classList.add('active')
        : tab.classList.remove('active')
    })
  }
}
