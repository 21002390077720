import { Controller } from '@hotwired/stimulus'

/**
 * Prevents clicks from going through.
 * Useful when pointer-events: none can't be used.
 */
export default class extends Controller {
  connect() {
    this.disabledElements.forEach(element =>
      element.addEventListener('click', event => {
        event.preventDefault()
      })
    )
  }

  get disabledElements() {
    return this.element.querySelectorAll('.disabled, [disabled="disabled"]')
  }
}
