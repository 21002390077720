import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['activatable', 'nextButton', 'prevButton']
  static values = {
    maxCountInCarousel: Number,
    automateCarousel: Boolean,
  }

  connect() {
    if (this.automateCarouselValue) {
      const cTimer = 4800
      this.intervalTimer = setInterval(() => this.moveCarouselRight(), cTimer)
    }
  }

  moveCarouselRight() {
    if (this.currentPosition < this.maxCountInCarouselValue - 1) {
      this.displayElement(`item-at-position-${this.nextPosition}`)
    } else {
      this.displayElement('item-at-position-0')
    }
    this.setArrowButtonStatus()
  }

  activate(event) {
    const slideId = event.currentTarget.dataset.idValue
    this.displayElement(slideId)
    if (this.hasPrevButtonTarget && this.hasNextButtonTarget) {
      this.setArrowButtonStatus()
    }
  }

  arrowActivate(event) {
    if (event.target.dataset.directionValue === 'prev') {
      this.displayElement(`item-at-position-${this.prevPosition}`)
    } else {
      this.displayElement(`item-at-position-${this.nextPosition}`)
    }
    this.setArrowButtonStatus()
  }

  displayElement(slideId) {
    this.activatableTargets.forEach(targetElement => {
      if (targetElement.dataset.idValue === slideId) {
        targetElement.classList.add('active')
      } else {
        targetElement.classList.remove('active')
      }
    })
  }

  get currentPosition() {
    return this.activatableTargets.findIndex(target => {
      return target.classList.contains('active')
    })
  }

  get nextPosition() {
    if (this.currentPosition === this.maxCountInCarouselValue) {
      return this.currentPosition
    }
    return this.currentPosition + 1
  }

  get prevPosition() {
    if (this.currentPosition === 0) {
      return this.currentPosition
    }
    return this.currentPosition - 1
  }

  setArrowButtonStatus() {
    if (this.currentPosition <= 0) {
      this.prevButtonTarget.disabled = true
    } else {
      this.prevButtonTarget.disabled = false
    }

    if (this.currentPosition >= this.maxCountInCarouselValue - 1) {
      this.nextButtonTarget.disabled = true
    } else {
      this.nextButtonTarget.disabled = false
    }
  }
  disconnect() {
    clearInterval(this.intervalTimer)
  }
}
