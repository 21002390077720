import { Controller } from '@hotwired/stimulus'
import * as CartService from '../api/Cart/Service'

export default class extends Controller {
  static targets = ['quantity']

  addPart(event) {
    const button = event.currentTarget
    const buttonOriginalTextContent = button.textContent
    button.textContent = 'Adding...'
    const partType = button.getAttribute('data-part-type')
    const partId = button.getAttribute('data-part-id')
    CartService.quickAddToCart(
      null,
      `/${partType}/${partId}/buy?quantity=${this.quantity}`,
      'Part',
      () => (button.textContent = buttonOriginalTextContent)
    )
  }

  get quantity() {
    return this.hasQuantityTarget ? this.quantityTarget.value : 1
  }
}
