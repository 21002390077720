import { Controller } from '@hotwired/stimulus'
import TurboClient from '../api/TurboClient'
import I18n from '../helpers/I18n'

export default class extends Controller {
  static targets = ['cardToken', 'cardholderName', 'form', 'button']

  static values = {
    stripekey: String,
  }

  initialize() {
    const stripe = Stripe(this.stripekeyValue)
    const elements = stripe.elements()

    const stripeElementStyle = {
      base: {
        color: '#1c344f',
        fontFamily: ['Larsseit', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif'].join(','),

        '::placeholder': {
          color: '#dbdbdb',
        },
      },
    }

    const cardNumberElement = elements.create('cardNumber', {
      style: stripeElementStyle,
    })
    cardNumberElement.mount('#card_number_element')
    this.setElementErrors(cardNumberElement, 'card-number-errors')

    const cardExpiryElement = elements.create('cardExpiry', {
      style: stripeElementStyle,
    })
    cardExpiryElement.mount('#exp_date_element')
    this.setElementErrors(cardExpiryElement, 'exp-date-errors')

    const cardCvcElement = elements.create('cardCvc', {
      style: stripeElementStyle,
    })
    cardCvcElement.mount('#cvc_element')
    this.setElementErrors(cardCvcElement, 'cvc-errors')

    this.stripe = stripe
    this.cardNumberElement = cardNumberElement

    this.validateCardholderName = this.validateCardholderName.bind(this)
    this.cardholderNameTarget.addEventListener('input', this.validateCardholderName)

    this.formTarget.addEventListener('submit', event => {
      event.preventDefault()
      this.submitCard()
    })

    this.formTarget.addEventListener('keypress', event => {
      if (event.key === 'Enter') {
        event.preventDefault()
        this.submitCard()
      }
    })
  }

  setElementErrors(cardElement, errorTargetElementId) {
    cardElement.on('change', function (event) {
      const element = document.getElementById(errorTargetElementId)

      if (event.error) {
        console.log(event.error)
        element.classList.remove('hidden')

        const errorMessage = I18n.t(`views.checkout.errors.${event.error.code}`) || event.error.message
        element.textContent = errorMessage
      } else {
        element.classList.add('hidden')
        element.textContent = ''
      }
    })
  }

  async submitCard() {
    // Custom button without form
    const buttonElement = this.buttonTarget
    const originalButtonElementText = buttonElement.textContent
    buttonElement.textContent = buttonElement.dataset.disableWith
    buttonElement.disabled = true

    const { token, error } = await this.stripe.createToken(this.cardNumberElement, {
      name: this.cardholderNameTarget.value,
    })

    let hasError

    if (error) {
      hasError = true
      if (error.type != 'validation_error') {
        toastr.error(
          error.message || 'Oops, something went wrong. Please contact customer support.'
        )
      }
      console.log(error)
    }

    if (!this.validateCardholderName()) {
      hasError = true
    }

    if (hasError) {
      buttonElement.textContent = originalButtonElementText
      buttonElement.disabled = false
      return
    }

    this.cardTokenTarget.value = token.id

    const turboClient = new TurboClient(this.formTarget)
    const turboClientResponse = await turboClient.perform()

    if (!turboClientResponse.response?.redirected) {
      buttonElement.textContent = originalButtonElementText
      buttonElement.disabled = false
    }
  }

  validateCardholderName() {
    const cardHolderNameErrorElement = document.getElementById('cardholder_name-errors')
    if (!this.cardholderNameTarget.value) {
      cardHolderNameErrorElement.textContent = I18n.t('views.checkout.errors.incomplete_cardholder')
      cardHolderNameErrorElement.classList.remove('hidden')
      return false
    } else {
      cardHolderNameErrorElement.textContent = ''
      cardHolderNameErrorElement.classList.add('hidden')
      return true
    }
  }
}
