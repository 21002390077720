import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['parent', 'children']
  static values = {
    parentId: String,
    selector: String,
  }

  toggleAll(e) {
    const parentCheckbox = e.target
    const parentID = parentCheckbox.getAttribute('id')
    this.childrenCheckboxes(parentID).forEach(checkbox => {
      checkbox.checked = parentCheckbox.checked
    })
  }

  updateParent(e) {
    const parentCheckbox = document.getElementById(this.parentIdValue)
    parentCheckbox.checked = e.target.checked
      ? this.childrenTargets.every(child => child.checked)
      : e.target.checked
  }

  childrenCheckboxes(parentId) {
    if (this.hasSelectorValue) {
      return document.querySelectorAll(this.selectorValue)
    } else {
      return this.parentTarget.querySelectorAll(`input[type='checkbox']:not(#${parentId})`)
    }
  }
}
