import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { id: String, galleryLength: Number, name: String }

  addCarousel() {
    const targetItem = document.querySelector(
      `#${this.nameValue} #modal-gallery-item-${this.idValue}`
    )
    const allItems = document.querySelectorAll(`#${this.nameValue} .modal-gallery-item`)
    allItems.forEach(modalItem => {
      if (targetItem === modalItem) {
        modalItem.style.left = '0'
        modalItem.style.right = '0'
        modalItem.classList.add('current-carousel')
      } else {
        modalItem.style.left = '-10000px'
        modalItem.classList.remove('current-carousel')
      }
    })
  }

  leftToggle() {
    const allItems = document.querySelectorAll(`#${this.nameValue} .modal-gallery-item`)
    const currentItem = document.querySelector(`#${this.nameValue} .current-carousel`)
    allItems.forEach((item, currentIndex) => {
      if (item === currentItem) {
        const previous = currentIndex === 0 ? this.galleryLengthValue - 1 : currentIndex - 1
        $(`#${this.nameValue} .modal-gallery-item`)
          .eq(currentIndex)
          .animate({ left: '200%' }, 'fast')
        $(`#${this.nameValue} .modal-gallery-item`).eq(previous).css({ left: '-200%' })
        $(`#${this.nameValue} .modal-gallery-item`).eq(previous).animate({ left: '0' }, 'fast')
        $(`#${this.nameValue} .modal-gallery-item`).eq(currentIndex).removeClass('current-carousel')
        $(`#${this.nameValue} .modal-gallery-item`).eq(previous).addClass('current-carousel')
      }
    })
  }
  rightToggle() {
    const allItems = document.querySelectorAll(`#${this.nameValue} .modal-gallery-item`)
    const currentItem = document.querySelector(`#${this.nameValue} .current-carousel`)
    allItems.forEach((item, currentIndex) => {
      if (item === currentItem) {
        const next = currentIndex === this.galleryLengthValue - 1 ? 0 : currentIndex + 1
        $(`#${this.nameValue} .modal-gallery-item`)
          .eq(currentIndex)
          .animate({ left: '-200%' }, 'fast')
        $(`#${this.nameValue} .modal-gallery-item`).eq(next).css({ left: '100%' })
        $(`#${this.nameValue} .modal-gallery-item`).eq(next).animate({ left: '0' }, 'fast')
        $(`#${this.nameValue} .modal-gallery-item`).eq(currentIndex).removeClass('current-carousel')
        $(`#${this.nameValue} .modal-gallery-item`).eq(next).addClass('current-carousel')
      }
    })
  }
}
