import { Controller } from '@hotwired/stimulus'
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  static outlets = ['overlay-spinner']
  static values = {
    params: Object,
    headers: Object,
    requestType: String,
    url: String,
    customErrorMessage: String,
    downloadJson: Boolean,
  }

  async sendRequest(e) {
    e.preventDefault()
    const request = new FetchRequest(this.requestTypeValue, this.urlValue, this.paramsValue)
    request.addHeader('Authorization', this.headersValue.Authorization)
    const response = await request.perform()
    if (response.ok && this.downloadJsonValue) {
      response.json
        .then(jsonData => {
          let dataStr = JSON.stringify(jsonData)
          let blob = new Blob([dataStr], { type: 'application/json' })
          let url = window.URL.createObjectURL(blob)
          let downloadLink = document.createElement('a')
          downloadLink.href = url
          downloadLink.download = 'data.json'

          document.body.appendChild(downloadLink)
          downloadLink.click()

          document.body.removeChild(downloadLink)
        })
        .catch(error => console.error('Error:', error))
    }
    if (!response.ok) {
      toastr.error(this.customErrorMessageValue)
    }

    if (this.hasOverlaySpinnerOutlet) {
      this.overlaySpinnerOutlets.forEach(overlaySpinner => overlaySpinner.remove())
    }
  }
}
