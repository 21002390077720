import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['replaceComponent']

  previewImage(e) {
    const file = e.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = event => {
        const uploadedImage = document.createElement('img')
        uploadedImage.setAttribute('src', event.target.result)
        uploadedImage.setAttribute(
          'data-image-upload-preview-target',
          this.replaceComponentTarget.dataset.imageUploadPreviewTarget
        )
        this.replaceComponentTarget.replaceWith(uploadedImage)
      }
      reader.readAsDataURL(file)
    }
  }
}
