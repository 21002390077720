import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['additionalElement', 'showMoreToggleButton']
  static values = { minimumToDisplay: { type: Number, default: 5 } }

  toggle() {
    this.additionalElementTargets.forEach((el, index) => {
      if (index > this.minimumToDisplayValue) {
        if (el.style.display === 'none' || el.style.display === '') {
          el.style.display = 'block'
        } else {
          el.style.display = 'none'
        }
      }
      if (this.showMoreToggleButtonTarget.innerText === 'Show Less') {
        this.showMoreToggleButtonTarget.innerText = `Show All (${
          this.additionalElementTargets.length - this.minimumToDisplayValue
        })`
      } else {
        this.showMoreToggleButtonTarget.innerText = 'Show Less'
      }
    })
    this.element.scrollIntoView({ block: 'end', inline: 'nearest' })
  }
}
